import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import GoogleMapsSearchInput from '@modules/ClassSearch/GoogleMapsSearchInput.vue';
import { todayISO } from '@ts/Util/datetime';
const GoogleMapsSearchInputContainer = defineComponent({
    name: 'GoogleMapsSearchInputContainer',
    components: {
        GoogleMapsSearchInput
    },
    setup() {
        return { todayISO };
    },
});
createApp(GoogleMapsSearchInputContainer).mount('#google-maps-search-input-container');
