import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import CarouselShared from "@components/Core/CarouselShared.vue";
import Modal from '@components/Core/Modal.vue';
import { onMounted, ref } from 'vue';
const ModalsControllerContainer = defineComponent({
    name: 'ModalsControllerContainer',
    components: {
        Modal,
        CarouselShared,
    },
    setup() {
        const showModal = ref(false);
        const videoUrl = ref('');
        const toggleModal = (videoId) => {
            showModal.value = !showModal.value;
            videoUrl.value = showModal.value ? `//www.youtube.com/embed/${videoId}?rel=0` : '';
        };
        onMounted(() => {
            document.addEventListener('toggle-modal', (event) => {
                toggleModal(event.detail.modalId);
            });
        });
        return {
            toggleModal,
            showModal,
            videoUrl,
        };
    }
});
document.querySelectorAll('.homepage-modals-container').forEach(element => {
    createApp(ModalsControllerContainer).mount(element);
});
